body {
  font-family: "futura-pt !important";
}

#root {
  overflow: hidden;
}

.biconomy-text {
  color: #459847;
}

.header {
  z-index: 100;
  display: flex;
  color: #4a4a4a;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
  padding-top: 14px;
  padding-bottom: 5px;
  position: fixed;
  width: 100%;
  height: 5.4rem;
}

.logo {
  float: left;
  margin-left: 30px;
  font-size: 3em;
  color: #3D678D;
}

.logo-second-half {
  font-weight: bold;
}

.transaction-container {
  display: flex;
  background: #56B572;
  padding: 5px;
  color: #fff;
  justify-content: center;
  margin-top: 0rem;

}

.transaction-hash-close-icon {
  cursor: pointer;
  margin-left: auto;
  margin-right: 25px;
  font-size: 1.1em;
}

.transaction-label {
  margin-left: auto;
}

.transaction-hash {
  margin-left: 10px;
}

.transaction-hash a {
    color: #fff;
    text-decoration: underline;
    font-size: bold;
}

.user-info {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0px 30px 0px 10px;
}

.torus-login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.portis-login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.metamask-login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.torus-note {
  text-align: center;
  padding-top: 5px;
}

.get-started {
  font-weight: bold!important;
}

.main-content {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  padding-top: 5.4rem;
}

#main-body {
  display: flex;
  flex-direction: row;
}

.my-fav-list {
  list-style: none;
  margin: 0px;
  max-width: 250px;
  width: 250px;
}

.my-list-container {
  padding: 10px;
}

.book-list-container {
  justify-content: center;
  padding: 10px 10px 10px 0px;
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  border-top: 1px solid #D8D8D8;
}

.book-tile {
  max-width: 250px;
  margin: 5px 10px 5px 0px;
  padding: 5px;
  border-radius: 0px!important;
}

.book-tile-image {
  height: 250px;
  width: 171px;
  margin: 10px 7px 0 7px;
}

.book-tile-rate-button {
  background: #5fd39f!important;
  color: #fff!important;
  font-weight: bold!important;
  border-color: #5fd39f!important;
  display: flex!important;
  align-items: center;
  padding: 5px 10px!important;

}

.book-tile-header {
  background-color: #fff;
  border-bottom: none;
  height: 30px;
  padding: 5px 1px 2px 1px!important;
}

.add-icon {
  font-weight: bold;
  font-size: 1.4em!important;
  margin-right: 5px;
}

.book-tile-footer {
  padding: .2em!important;
}

.book-tile-body {
  display: flex;
  justify-content: center;
  padding: 1em 1em .2em 1em!important;
}

.rating-label {
  background: transparent;
  border: 0;
  color: #999999;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  font-size: 11px;
  height: 16px;
  line-height: 14px;
  margin: 0 auto;
  padding: 3px 6px 0 6px;
  width: 100px;
  font-family: "Lato", "Helvetica Neue", "Helvetica", sans-serif;
}

.book-tile-overlay-status {
  background: #999;
  color: #fff;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 100%;
  font-size: 2em;
  text-align: center;
  opacity: .9;
  align-items: center;
}

.user-balance {
  background: #444;
  color: #fff;
  border-radius: .25rem;
  line-height: 1.2;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.book-tile-processing {
  background-color: #0d47a1;
  flex-direction: column;
}

.book-tile-done {
  background-color: #5FBA7D;
}

.book-shelf-login-message {
  display: flex;
  justify-content: center;
  color: #999;
  text-align: center;
}

.empty-book-shelf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #999;
  text-align: center;
}

.book-tile-avg-rating {
  color: #666;
  font-size: 12px;
  font-weight: bold;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.rating-bar {
  width: fit-content;
}

.rating-container {
  display: flex;
  justify-content: center;
  height: 20px;
}

.login-back-drop {
  opacity: 0.5 !important;
}

.congrats-back-drop {
  opacity: 0.5 !important;
}
.congrats-button-container {
  width: 60%;
  display: flex;
  margin-top: 2rem;
  margin-left: auto;
  justify-content: space-between;
  margin-right: auto;
}

.congrats-header .modal-title{
  display: flex;
  justify-content: space-between;
}

.congrats-modal-body {
  text-align: justify;
}

.sign-in-modal-body {
  padding: 1rem!important;
}

.biconomy-logo {
  height: 32px;
}

.biconomy-modal-logo {
  height: 25px;
}

.main-content-container {
  flex: 1;
}

.main-title {
  margin-bottom: 10px;
  font-size: 1.3em;
  margin-top: 7px;
  color: #50B065;
  text-align: center;
}

.close-modal {
  position: absolute !important;
  top: 1rem !important;
  right: 1rem !important;
  cursor: pointer;
  font-size: 25px !important;
}

.powered-by {
  z-index: 1;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.login-form {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.register-form {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.error-message {
  background-color: #ef5350;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
}

.success-message {
  background-color: #50b065;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
}

.login-button {
  margin-top: 10px;
}

.register-form {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.submit-button {
  font-weight: bold;
}

.register-button {
  float: right;
}

.logout-btn {
  font-weight: bold!important;
  font-size: 1em!important;
}

.label {
  margin-bottom: 0px!important;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.clear {
  clear: both;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.bookshelf-header {
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bookshelf-header-icon {
  font-size: 1.2em;
}

.switchDAppsBtn {
  background-color: #3D678D!important;
  color: #fff!important;
  font-weight: 600!important;
  margin-right: 1rem;
}

.network-select {
  width: 200px!important;
  font-weight: bold!important;
  border-color: #3D678D!important;
  border-width: 1px!important;
  color: #444!important;
  margin-right: 1rem;
}

._loading_overlay_wrapper--active {
  display: block!important;
}

._loading_overlay_wrapper {
  width: 100%;
  background: #444;
  height: 100%;
  top: 0px;
  position: fixed!important;
  opacity: .8;
  display: none;
  z-index: 101;
}
.user-balance-container {
  display: flex;
}

@media only screen and (max-width: 768px) {

  ._loading_overlay_content {
    padding: 20px;
  }

  .select-network-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    padding: 5px 0px;
    margin-bottom: 1rem;
  }

  .switchDAppsBtn {
    width: 100%;
    margin-bottom: 5px;
    margin-right: 0px!important;
  }

  .user-balance-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  .user-balance {
    flex-grow: 1;
  }

  .header {
    float: none;
    text-align: center;
    margin-left: 0px;
    flex-direction: column;
    padding: 2px 5px;
    position: fixed;
    width: 100%;
    height: 13rem;
  }

  .logout-btn {
    margin-bottom: 0px!important;
  }

  .logo {
    float: none;
    margin-left: 10px;
  }

  .transaction-container {
    display: block;
    word-wrap: break-word;
    text-align: center;
    position: relative;
    margin-top: 1rem;
  }

  .transaction-hash-close-icon {
    position: absolute;
    top: 0px;
    right: 0;
    margin-right: 5px;
  }

  .transaction-hash {
    margin-left: 0px;
  }

  .user-info {
    margin-left: auto;
    justify-content: center;
    padding: 0px 10px 5px 10px;
    font-size: 1em;
    margin-right: auto;
    flex-direction: column;
  }


  .my-fav-list {
    margin-left: auto;
    margin-right: auto;
  }

  .book-list-container {
    padding: 10px 0px 10px 0px;
    justify-content: center;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
    padding-top: 13rem;
  }

  #main-body {
    display: flex;
    flex-direction: column;
  }

  .main-content-container {
    flex: 1;
    flex-direction: column;
  }

  .main-title {
    margin: 0px;
    background: #a5d6a7;
    color: #3D678D;
    padding: 5px 0px;
  }

  .congrats-button-container {
    width: 80%;
  }
}
